import * as React from "react";
import Header from "../header/header";
import Footer from "../footer/footer";
import "./layout.scss";
// markup
const Layout = (props) => {
  return (
    <>
      <div id="page-container">
        <Header />

        <div id="content-wrap">{props.children}</div>
        <footer id="footer">
          <Footer />
        </footer>
      </div>
    </>
  );
};

export default Layout;
