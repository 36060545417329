import * as React from "react";
import { Container, Navbar, Nav } from "react-bootstrap";
import whiteLogo from "../../images/netaro_brand_white.svg";
import { Squash as Hamburger } from "hamburger-react";
import "./header.scss";
import AniLink from "gatsby-plugin-transition-link/AniLink";

// markup
const Header = () => {
  return (
    <Navbar collapseOnSelect expand="lg" sticky="top">
      <Container className="navigation">
        <Navbar.Brand>
          <AniLink paintDrip hex="#25ACBE" to="/">
            <img src={whiteLogo} height="50" alt="Logo Netaro" />
          </AniLink>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav">
          <Hamburger color="#fff" />
        </Navbar.Toggle>
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="justify-content-end" style={{ width: "100%" }}>
            <AniLink paintDrip hex="#25ACBE" to="/">
              HOME
            </AniLink>
            <AniLink paintDrip hex="#25ACBE" to="/showcase">
              SHOWCASE
            </AniLink>
            <a href="mailto:hello@netaro.ch">KONTAKT</a>
            <a className="action-button" href="tel:0041786713848">
              JETZT ANRUFEN
            </a>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default Header;
