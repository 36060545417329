import * as React from "react";
import { Container, Row, Col } from "react-bootstrap";
import "./footer.scss";
import AniLink from "gatsby-plugin-transition-link/AniLink"
// markup
const Footer = () => {
  var year = new Date().getUTCFullYear();
  return (
    <div style={{background: "#25ACBE"}}>
      <Container className="footer-container">
        <Row>
          <Col>
            <span>© {year} Netaro | <AniLink paintDrip  hex="#25ACBE" to="/impressum/">Impressum</AniLink></span>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Footer;
